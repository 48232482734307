<template>
  <div class="sensotex">
    <div class="section-innovations section-bg">
      <div class="section-inner">
        <img src="~/static/sensotex/middle-circle.png" alt="Innovations of softness" class="img-block">
        <SfHeading
          :level="2"
          :style-level="2"
          title="Innovations of softness"
        />
      </div>
    </div>
    <div class="section-kefalis section-text section-bg">
      <div class="section-container">
        <SfHeading
          class="section-title-gradient"
          :level="2"
          :style-level="2"
          title="KEFALIS SOLUTION"
        />
        <p class="text-large"><span class="green-color">Sensotex®</span> is an advanced fabric created by Togas
          Innovations.
          This new material is made of eucalyptus fiber and impregnated with a special solution, <span
            class="yellow-color">Kefalis</span>,
          that we created using the algae extract from the Ionian Sea.</p>
        <p class="text-large">This treatment increases the silkiness of the fibers by 30%, makes them stronger by 10%
          and less
          shrinkable by 20%.</p>
      </div>
    </div>
    <div class="section-img">
      <img src="~/static/sensotex/table.png" alt="with Kefalis solution" class="img-block section-img-desktop">
      <img src="~/static/sensotex/table-mob.png" alt="with Kefalis solution" class="img-block section-img-mob">
    </div>
    <div class="section-eucalyptus section-text section-bg">
      <div class="section-container">
        <SfHeading
          class="section-title-gradient"
          :level="2"
          :style-level="2"
          title="EUCALYPTUS"
        />
        <p class="text-large">We selected the fabrics from natural eucalyptus fibers as the basis for <span
          class="green-color">Sensotex®</span>.
          They are hypoallergenic, breathable and hygroscopic,
          having excellent thermal conductivity and shape retention and don’t have static electricity build-up.</p>
        <p class="text-large"><span class="green-color">Sensotex®</span> impregnated with <span
          class="yellow-color">Kefalis</span> is easy-care and has increased strength and longevity. The eucalyptus oil
          such fabrics contain is beneficial for people with breathing problems.</p>
      </div>
    </div>
    <div class="section-climate section-text section-bg">
      <div class="section-container">
        <SfHeading
          class="section-title-gradient"
          :level="2"
          :style-level="2"
          title="CLIMATE CONTROL"
        />
        <p class="text-large">Eucalyptus fiber has high thermal insulation otherwise known as "natural climate control".
          <span class="green-color">Sensotex®</span> fabrics keep you refreshingly cool in any environment regardless of
          the season. </p>
        <p class="text-large">This is also facilitated by the active mechanisms of absorption and release of excessive
          moisture – the porous structure of eucalyptus fiber allows items made from <span
            class="green-color">Sensotex®</span> to literally breathe.</p>
      </div>
    </div>
    <div class="section-gentleness section-text section-bg">
      <div class="section-container">
        <SfHeading
          class="section-title-gradient"
          :level="2"
          :style-level="2"
          title="GENTLENESS OF SILK"
        />
        <p class="text-large"><span class="green-color">Sensotex®</span> fabrics are lightweight and soft, like silk.
          One of the reasons we were working on the <span class="yellow-color">Kefalis</span> solution was to obtain a
          material similar to silk, which is the pinnacle of textile art.
          At the same time we wanted the production to require fewer natural resources as it does to obtain silk. </p>
        <p class="text-large">As a result, we have achieved our goal: the combination of selected eucalyptus raw
          materials and
          innovative impregnation <span class="yellow-color">Kefalis</span> does wonders.</p>
        <p class="text-large text-dash">Natural materials need synthetic components to acquire these properties, while
          the
          <span class="green-color">Sensotex®</span> already contains all these properties by itself.</p>
      </div>
    </div>
    <div class="section-sustainability section-text section-bg">
      <div class="section-container">
        <SfHeading
          class="section-title-gradient"
          :level="2"
          :style-level="2"
          title="SUSTAINABILITY"
        />
        <p class="text-large"><span class="green-color uppercase">SENSOTEX®</span> uses the fibers produced from
          eucalyptus
          trees from special farms in Australia.
          These trees are grown exclusively for production purposes and grow rapidly: already in a year they grow to
          1.5-2 meters, up to 10 meters – by three years,
          and to 20-25 meters – by 10 years, the trunk diameter reaching 25-30 cm.</p>
        <p class="text-large">Basically, this fiber is cellulose.
          But the production of traditional cellulose fiber heavily pollutes the air and natural waters.
          Moreover, common cellulose fiber is not environmentally friendly: it contains traces of chemicals used in the
          process of their production.</p>
        <p class="text-large">
          While <span class="green-color">Sensotex®</span> fabrics are eco-friendly: no chlorine-containing substances
          are used in their production, and no pesticides – in eucalyptus cultivation. We use an organic solvent in
          production.
          Moreover, no less than 99,5 % of this solvent is reused, making the production practically zero-waste, a
          closed-loop system.
        </p>
        <p class="text-large">
          Highly important: <span class="green-color uppercase">SENSOTEX®</span> production requires several times less
          power and water than the production of common cellulose or cotton.
          We are also working on the ways to expand the use of renewable energy sources.
        </p>
        <div class="section-inner">
          <img src="~/static/sensotex/sustainability-img.png"
               alt="Like all natural fibers, eucalyptus fibers are biodegradable." class="img-block">
          <span class="section-inner__text">Like all natural fibers, eucalyptus fibers are biodegradable.</span>
        </div>
      </div>
    </div>
    <section class="categories">
      <div class="section-container">
        <div class="sf-heading section-title-gradient-black">
          <h2 class="sf-heading__title sf-heading__title--2">
            PRODUCTS
            <span class="green-color">SENSOTEX®</span>
          </h2>
        </div>
      </div>
      <div class="categories-grid">
        <SfLink
          v-for="category in categories"
          :key="category.title"
          :link="localePath(getCatLink(category.link))"
        >
          <picture class="img-block">
            <source
              :srcset="require(`~/static/sensotex/categories/${category.imageName}-d.webp`)"
              media="screen and (min-width: 1024px)"
            />
            <img
              :src="require(`~/static/sensotex/categories/${category.imageName}-m.webp`)"
              :alt="category.title"
            />
          </picture>
          <span class="categories-title">{{ category.title }}</span>
        </SfLink>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import {
  SfHeading,
  SfLink
} from '~/components';
import { useUiHelpers } from '~/composables';

export default defineComponent({
  name: 'Sensotex',
  components: {
    SfHeading,
    SfLink
  },
  setup() {

    const {getCatLink} = useUiHelpers();

    const categories = [
      {
        title: 'Bedding',
        link: {
          url_path: 'bed-linen',
          url_suffix: '.html',
        },
        imageName: 'bedding',
      },
      {
        title: 'Pillows',
        link: {
          url_path: 'pillows',
          url_suffix: '.html',
        },
        imageName: 'pillows',
      },
      {
        title: 'Duvets',
        link: {
          url_path: 'duvets',
          url_suffix: '.html',
        },
        imageName: 'duvets',
      },
      {
        title: 'Bath',
        link: {
          url_path: 'bath',
          url_suffix: '.html',
        },
        imageName: 'bath',
      },
    ];

    return {
      categories,
      getCatLink
    };
  },
});
</script>

<style lang="scss" scoped>
@import "styles/components/togas/Landings.scss";

.sensotex {
  .green-color {
    color: #3ED882;

    &.uppercase {
      text-transform: uppercase;
    }
  }

  .yellow-color {
    color: #95C11F;
  }

  .section {

    &-innovations {
      background-image: url("~static/sensotex/top-bg.jpg");
      padding: 50px 0;

      --heading-text-align: center;

      .section-inner {
        text-align: center;
        max-width: 500px;
        margin: 0 auto;
        position: relative;

        &__text {
          font-size: 36px;
          font-family: var(--font-family--secondary);
          font-weight: 400;
          line-height: 48px;
          text-transform: uppercase;
          position: absolute;
          bottom: 30px;
          left: 0;
          right: 0;

          @include for-mobile($mobile-max) {
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            position: static;
            margin-top: 30px;
            display: block;
            padding: 0 70px;
          }
        }
      }

      .img-block {
        max-width: 500px;
        padding: 0 46px;
      }

      @include for-mobile($mobile-max) {
        padding: 140px 0;
      }
    }

    &-text {
      padding: 192px 0;
      box-sizing: border-box;

      @include for-mobile($mobile-max) {
        padding: 150px 18px;
      }
    }

    &-kefalis {
      background-image: url("~static/sensotex/kefalis-bg.jpg");
      margin-top: -1px;

      .section-text {
        padding: 204px 0;
      }
    }

    &-eucalyptus {
      background-image: url("~static/sensotex/eucalyptus-bg.jpg");
    }

    &-climate {
      background-image: url("~static/sensotex/climate-bg.jpg");
    }

    &-gentleness {
      background-image: url("~static/sensotex/gentleness-bg.jpg");
      padding-top: 115px;
      padding-bottom: 140px;

      @include for-mobile($mobile-max) {
        padding: 70px 18px;
      }
    }

    &-sustainability {
      background-image: url("~static/sensotex/sustan-bg.jpg");

      @include for-mobile($mobile-max) {
        padding: 80px 18px;
      }

      .section-inner {
        display: flex;
        justify-content: center;
        padding-top: 90px;

        @include for-mobile($mobile-max) {
          padding-top: 44px;
        }

        &__text {
          font-size: 36px;
          font-family: var(--font-family--secondary);
          font-weight: 400;
          line-height: 48px;
          color: var(--togas-white-color);
          left: 0;
          right: 0;
          top: 50%;
          position: absolute;
          max-width: 550px;
          margin: 0 auto;

          @include for-mobile($mobile-max) {
            top: 40%;
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            max-width: 340px;
          }
        }
      }

      img {
        max-width: 318px;

        @include for-mobile($mobile-max) {
          max-width: 280px;
        }
      }
    }

    &-img {
      max-width: 936px;
      margin: 0 auto;
      font-size: 0;

      @include for-desktop($mobile-max) {
        padding: 80px 0;
      }

      .section-img-desktop {
        @include for-mobile($mobile-max) {
          display: none;
          padding: 0 20px;
        }
      }

      .section-img-mob {
        @include for-desktop($tablet-min) {
          display: none;
        }
      }
    }

    &-container {
      max-width: 635px;
      margin: 0 auto;

      .section-inner {
        text-align: center;
        position: relative;
      }

      .text-dash {
        margin-top: 80px;
        padding-left: 20px;
        position: relative;

        @include for-mobile($mobile-max) {
          margin-top: 60px;
        }

        &:before {
          background: linear-gradient(180deg, var(--togas-white-color) 0%, rgba(255, 255, 255, 0.00) 100%);
          content: "";
          width: 3px;
          height: 72px;
          display: block;
          position: absolute;
          left: 0;
        }
      }
    }
  }

  .categories {
    background-color: var(--togas-grey-50-color);
    padding: 60px 0;
    --link-text-decoration: none;
    --heading-color: var(--togas-black-color);

    @include for-desktop() {
      --heading-margin: 0 0 var(--spacer-2xl) 0;
      padding: 80px 0;
    }

    &-grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 40px;
      max-width: var(--togas-content-max-width);
      padding: 0 var(--spacer-sm);
      margin: 0 auto;

      @include for-desktop() {
        grid-template-columns: repeat(4, 1fr);
        gap: 80px;
        padding: 0 var(--spacer-xl);
      }
    }

    &-title {
      display: block;
      margin-top: 16px;
      color: var(--togas-black-color);
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      text-align: center;

    }
  }
}
</style>
